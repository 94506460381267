// const baseURL = "http://localhost:2030/api/v1";
const baseURL = "https://nodejs-ecommerce-api.onrender.com/api/v1";

// import { createRequire } from "module";
// const require = createRequire(import.meta.url);

// const path = require('path');

// const configDir = `./${process.env.CONFIG_DIR}`;
// console.log("baseURL.js process.env.SHOP_CONTEXT :: ", path.join(__dirname, configDir, `./${process.env.SHOP_CONTEXT}`))

// require('dotenv').config({ path: path.join(__dirname, configDir, `./${process.env.SHOP_CONTEXT}`) })

// require('dotenv').config()

// const baseURL = process.env.REACT_APP_BACKEND_URL;

console.log(`baseURL :: ${baseURL}`)
export default baseURL;
